import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getFilterCountApi } from 'src/data/newListings'
import { segment } from 'src/lib/Segments'

import ActionButton from '@components/buttons/actionButton'
import FourStarRating from '@components/newListing/filters/fourStarRating'
import GoodCause from '@components/newListing/filters/goodCause'
import RentStabilized from '@components/newListing/filters/rentStabilized'
import { useAppSelector } from '@redux/hooks'
import { formatCount } from '@utility/Utilities'

function LandingFilters() {
  const router = useRouter()

  const [hasFourStar, setHasFourStar] = useState(true)
  const [rentStabilized, setRentStabilized] = useState(false)
  const [goodCause, setGoodCause] = useState(false)
  const [count, setCount] = useState(null)
  const [countLoad, setCountLoad] = useState(true)

  const { token } = useAppSelector((state) => state.currentUser)

  useEffect(() => {
    getFilterCount()
  }, [hasFourStar, rentStabilized, goodCause])

  async function getFilterCount() {
    setCountLoad(true)
    const payload = {
      filters: {
        hasBuildingMinFourRating: hasFourStar,
        hasBuildingGoodCauseEviction: goodCause,
        isBuildingRentStabilized: rentStabilized,
      },
    }

    try {
      const count = await getFilterCountApi(payload, token)
      setCount(count.count)
    } catch (error) {
      console.error('Error fetching filter count:', error)
    } finally {
      setCountLoad(false)
    }
  }

  return (
    <div className="my-8 gap-6 space-y-6 rounded-2xl bg-blue-25 py-4 px-4 sm:space-y-0 sm:py-8 sm:px-8">
      <div className="text-center">
        <div className="text-xl font-semibold text-dark-900">
          Filters you can't find anywhere else
        </div>
        <div className="py-4 text-dark-900">
          Browse for rent-stabilized apartments and buildings with Good Cause protections
        </div>
      </div>
      <div className="mx-auto max-w-screen-sm">
        <div className="mb-4">
          <FourStarRating
            segmentBase="home"
            deployFilters={false}
            setHasFourStar={setHasFourStar}
            hasFourStar={hasFourStar}
          />
        </div>
        <RentStabilized
          segmentBase="home"
          deployFilters={false}
          rentStabilized={rentStabilized}
          setRentStabilized={setRentStabilized}
        />
        <GoodCause
          segmentBase="home"
          deployFilters={false}
          goodCause={goodCause}
          setGoodCause={setGoodCause}
        />
        <div className="mt-4 flex justify-center">
          <ActionButton
            label={count ? `Search ${formatCount(count)} listings` : `Search listings`}
            onClick={() => {
              segment?.home_filters_listings_search()
              router.push('/listings?showOnboarding=true')
            }}
            customStyle="rounded-full text-sm"
            isLoading={countLoad}
            disabled={countLoad}
          />
        </div>
      </div>
    </div>
  )
}

export default LandingFilters
