import Image from 'next/image'
import { AiOutlineQuestion } from 'react-icons/ai'

import chevron from '@public/chevron-down.svg'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'

function LandingFaqs() {
  return (
    <div className="mt-8 gap-6 space-y-6 rounded-2xl bg-white py-4 px-4 sm:space-y-0">
      <AiOutlineQuestion size={24} color="#6764A1" className="mx-auto" />
      <div className="py-2 text-center text-xl font-semibold text-dark-900">What's openigloo?</div>
      <div className="text-center text-dark-900">
        openigloo is a platform for tenants to find apartments from highly-rated landlords. The
        platform combines open-source city data with crowdsourced tenant feedback, giving renters an
        inside look into buildings and property owners. openigloo's mission is to bring transparency
        to rental markets.
      </div>
      <div className="!mt-4 w-full">
        <Accordion transition transitionTimeout={250}>
          <AccordionItem
            header={({ state: { isEnter } }) => (
              <div className="flex w-full items-center justify-between space-x-4">
                <h2 className="w-[90%] text-base font-semibold text-dark-900">
                  How to use openigloo during your NYC apartment search?
                </h2>
                <Image
                  className={`ml-auto transition-transform duration-200 ease-out ${
                    isEnter && 'rotate-180'
                  }`}
                  src={chevron}
                  alt="Chevron"
                  unoptimized
                />
              </div>
            )}
            className="border-b"
            buttonProps={{
              className: ({ isEnter }) =>
                `flex w-full p-4 text-left hover:bg-slate-100 ${isEnter && 'bg-slate-200'}`,
            }}
            contentProps={{
              className: 'transition-height duration-200 ease-out',
            }}
            panelProps={{ className: 'p-4' }}
          >
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="/listings?showOnboarding=true"
                className="font-semibold text-bluegray-300 underline"
              >
                Browse Listings
              </a>
              : Search current and future apartments, including rent-stabilized options, tailored to
              your move-in date.
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <span className="font-semibold">Read Landlord and </span>
              <a href="/review" className="font-semibold text-bluegray-300 underline">
                Building Reviews
              </a>
              : Access anonymous tenant reviews on pros, cons, rents, and utilities.
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <span className="font-semibold">Check City Data</span>: Look up landlord details,
              building violations, bedbug history, and pending legal cases.
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <span className="font-semibold">Ask Renters Questions</span>: Use the anonymous Q&A
              feature to get insights from other tenants.
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a href="/blogs" className="font-semibold text-bluegray-300 underline">
                Utilize Resources
              </a>
              : Learn about laws, rent negotiations, and more via openigloo's guides.
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <span className="font-semibold">Apply to </span>
              <a
                href="/nyc/apartments-for-rent-nyc"
                className="font-semibold text-bluegray-300 underline"
              >
                Verified Apartments
              </a>
              : Find and apply to vetted, quality housing options.
            </li>
          </AccordionItem>
          <AccordionItem
            header={({ state: { isEnter } }) => (
              <div className="flex w-full items-center justify-between space-x-4">
                <h2 className="w-[90%] text-base font-semibold text-dark-900">
                  Are my reviews anonymous? Is my identity protected?
                </h2>
                <Image
                  className={`ml-auto transition-transform duration-200 ease-out ${
                    isEnter && 'rotate-180'
                  }`}
                  src={chevron}
                  alt="Chevron"
                  unoptimized
                />
              </div>
            )}
            className="border-b"
            buttonProps={{
              className: ({ isEnter }) =>
                `flex w-full p-4 text-left hover:bg-slate-100 ${isEnter && 'bg-slate-200'}`,
            }}
            contentProps={{
              className: 'transition-height duration-200 ease-out',
            }}
            panelProps={{ className: 'p-4' }}
          >
            <div className="text-sm text-dark-900">
              All reviews submitted through the openigloo platform are completely ANONYMOUS.
              openigloo will NEVER share your personal information. You can read more details in our{' '}
              <a href="/privacy-policy" className="font-semibold text-bluegray-300 underline">
                privacy policy
              </a>
              . If you have an inquiry please write to us at{' '}
              <a
                href="mailto:dataprotection@openigloo.com"
                className="font-semibold text-bluegray-300 underline"
              >
                dataprotection@openigloo.com
              </a>
              .
            </div>
          </AccordionItem>
          <AccordionItem
            header={({ state: { isEnter } }) => (
              <div className="flex w-full items-center justify-between space-x-4">
                <h2 className="w-[90%] text-base font-semibold text-dark-900">
                  Where does openigloo source its data?
                </h2>
                <Image
                  className={`ml-auto transition-transform duration-200 ease-out ${
                    isEnter && 'rotate-180'
                  }`}
                  src={chevron}
                  alt="Chevron"
                  unoptimized
                />
              </div>
            )}
            className="border-b"
            buttonProps={{
              className: ({ isEnter }) =>
                `flex w-full p-4 text-left hover:bg-slate-100 ${isEnter && 'bg-slate-200'}`,
            }}
            contentProps={{
              className: 'transition-height duration-200 ease-out',
            }}
            panelProps={{ className: 'p-4' }}
          >
            <div className="text-sm text-dark-900">
              All data points presented on openigloo are sourced from:
            </div>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://www.nyc.gov/site/hpd/index.page"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                NYC Housing Preservation & Development
              </a>
              .
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://www.nyc.gov/content/oti/pages/"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                Office of Technology and Innovation
              </a>
              .
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://www.nyc.gov/site/doh/about/about-doh.page"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                Department of Buildings, Department of Health and Mental Hygiene
              </a>
              .
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://www.nyc.gov/site/doi/index.page"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                Department of Investigation
              </a>
              .
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://rentguidelinesboard.cityofnewyork.us/"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                Rent Guidelines Board
              </a>
              .
            </li>
            <li className="pt-2 text-sm text-dark-900">
              <a
                href="https://www.nyc.gov/site/finance/index.page"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                Department of Finance
              </a>
              .
            </li>
          </AccordionItem>
          <AccordionItem
            header={({ state: { isEnter } }) => (
              <div className="flex w-full items-center justify-between space-x-4">
                <h2 className="w-[90%] text-base font-semibold text-dark-900">
                  How frequently is data on openigloo updated?
                </h2>
                <Image
                  className={`ml-auto transition-transform duration-200 ease-out ${
                    isEnter && 'rotate-180'
                  }`}
                  src={chevron}
                  alt="Chevron"
                  unoptimized
                />
              </div>
            )}
            className="border-b"
            buttonProps={{
              className: ({ isEnter }) =>
                `flex w-full p-4 text-left hover:bg-slate-100 ${isEnter && 'bg-slate-200'}`,
            }}
            contentProps={{
              className: 'transition-height duration-200 ease-out',
            }}
            panelProps={{ className: 'p-4' }}
          >
            <div className="text-sm text-dark-900">
              Data presented on openigloo is updated daily and reflects changes made to the original
              sources on the Housing Preservation & Development Agency, and City Data websites.
            </div>
          </AccordionItem>
          <AccordionItem
            header={({ state: { isEnter } }) => (
              <div className="flex w-full items-center justify-between space-x-4">
                <h2 className="w-[90%] text-base font-semibold text-dark-900">
                  What's included in the openigloo subscription?
                </h2>
                <Image
                  className={`ml-auto transition-transform duration-200 ease-out ${
                    isEnter && 'rotate-180'
                  }`}
                  src={chevron}
                  alt="Chevron"
                  unoptimized
                />
              </div>
            )}
            className="border-b"
            buttonProps={{
              className: ({ isEnter }) =>
                `flex w-full p-4 text-left hover:bg-slate-100 ${isEnter && 'bg-slate-200'}`,
            }}
            contentProps={{
              className: 'transition-height duration-200 ease-out',
            }}
            panelProps={{ className: 'p-4' }}
          >
            <div className="text-sm text-dark-900">
              openigloo subscribers have full access to reviews and can read building report(s)
              based on their{' '}
              <a
                href="/subscription"
                target="_blank"
                rel="noreferrer nofollow"
                className="font-semibold text-bluegray-300 underline"
              >
                subscription
              </a>{' '}
              level.
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default LandingFaqs
