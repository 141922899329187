import Link from 'next/link'
import { segment } from 'src/lib/Segments'

import m1 from '@public/marketplace-1.png'
import m2 from '@public/marketplace-2.png'
import m3 from '@public/marketplace-3.png'
import m4 from '@public/marketplace-4.png'

function MarketPlace() {
  return (
    <div>
      <div className="text-xl font-semibold text-dark-900">
        Rent Your Next Apartment in A Few Clicks
      </div>
      <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <Link href={'/listings'}>
          <div
            onClick={() => segment.home_banner_browse_listings()}
            className={`flex cursor-pointer items-center justify-between rounded-3xl border-4 border-blue-25 bg-white p-4 transition-all duration-300 ease-in-out sm:border-transparent sm:hover:border-blue-25 sm:hover:px-6`}
          >
            <div className="w-4/5 pr-4">
              <div className="flex items-center space-x-2">
                <div className="text-xl font-semibold text-blue-500">1</div>
                <div className="w-20 rounded border-b-4 border-blue-500"></div>
              </div>
              <div className="pt-2 text-xl font-semibold text-dark-900">
                Browse available or <br /> future listings
              </div>
              <div className="pt-2 font-semibold text-dark-700">Find well-reviewed apartments</div>
            </div>
            <div className="max-w-[130px]">
              <img src={m1.src} alt="Browse available or future listings" />
            </div>
          </div>
        </Link>
        <Link href={'/subscription'}>
          <div
            onClick={() => segment.home_banner_research()}
            className={`flex cursor-pointer items-center justify-between rounded-3xl border-4 border-blue-25 bg-white p-4 transition-all duration-300 ease-in-out sm:border-transparent sm:hover:border-blue-25 sm:hover:px-6`}
          >
            <div className="w-4/5 pr-4">
              <div className="flex items-center space-x-2">
                <div className="text-xl font-semibold text-blue-500">2</div>
                <div className="w-20 rounded border-b-4 border-blue-500"></div>
              </div>
              <div className="pt-2 text-xl font-semibold text-dark-900">Do your research</div>
              <div className="pt-2 font-semibold text-dark-700">
                Read reviews and access building <br /> reports
              </div>
            </div>
            <div className="max-w-[130px]">
              <img src={m2.src} alt="Do your research" />
            </div>
          </div>
        </Link>
        <Link href={'/nyc/apartments-for-rent-nyc'}>
          <div
            onClick={() => segment.home_banner_contact_and_apply()}
            className={`flex cursor-pointer items-center justify-between rounded-3xl border-4 border-blue-25 bg-white p-4 transition-all duration-300 ease-in-out sm:border-transparent sm:hover:border-blue-25 sm:hover:px-6`}
          >
            <div className="w-4/5 pr-4">
              <div className="flex items-center space-x-2">
                <div className="text-xl font-semibold text-blue-500">3</div>
                <div className="w-20 rounded border-b-4 border-blue-500"></div>
              </div>
              <div className="pt-2 text-xl font-semibold text-dark-900">Contact agent & apply</div>
              <div className="pt-2 font-semibold text-dark-700">
                Schedule tours and get documents ready
              </div>
            </div>
            <div className="max-w-[130px]">
              <img src={m3.src} alt="Contact agent & apply" />
            </div>
          </div>
        </Link>
        <Link href={'/nyc/apartments-for-rent-nyc'}>
          <div
            onClick={() => segment.home_banner_sign_and_pay()}
            className={`flex cursor-pointer items-center justify-between rounded-3xl border-4 border-blue-25 bg-white p-4 transition-all duration-300 ease-in-out sm:border-transparent sm:hover:border-blue-25 sm:hover:px-6`}
          >
            <div className="w-4/5 pr-4">
              <div className="flex items-center space-x-2">
                <div className="text-xl font-semibold text-blue-500">4</div>
                <div className="w-20 rounded border-b-4 border-blue-500"></div>
              </div>
              <div className="pt-2 text-xl font-semibold text-dark-900">Sign & pay rent</div>
              <div className="pt-2 font-semibold text-dark-700">
                Sign, pay and secure your lease
              </div>
            </div>
            <div className="max-w-[130px]">
              <img src={m4.src} alt="Sign & pay rent" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default MarketPlace
