import Image from 'next/image'
import { segment } from 'src/lib/Segments'

import appStoreBadge from '@public/badge_app_store.svg'
import androidBadge from '@public/badge_google_play.svg'

function DownloadApp() {
  return (
    <div className="mt-8 gap-6 space-y-6 rounded-2xl bg-white py-4 px-4 sm:space-y-0">
      <div className="text-center">
        <div className="text-xl font-semibold text-dark-900">Download the openigloo app</div>
        <div className="py-4 text-dark-900">
          Discover top-rated landlords, browse apartment listings, and apply for your dream
          apartment.
        </div>
      </div>
      <div className="mx-auto max-w-screen-sm">
        <div className="flex justify-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.openigloo&amp;hl=en_US&amp;gl=US"
            id="nav_google_play"
          >
            <Image
              onClick={() => segment.global_google_playstore('landing')}
              layout="fixed"
              objectFit="fill"
              width={162}
              height={48}
              alt="android store"
              src={androidBadge}
              unoptimized
            />
          </a>
          <a href="https://apps.apple.com/us/app/openigloo/id1521697583" id="nav_app_store">
            <Image
              onClick={() => segment.global_app_store('landing')}
              layout="fixed"
              objectFit="fill"
              width={162}
              height={48}
              alt="app store"
              src={appStoreBadge}
              unoptimized
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DownloadApp
