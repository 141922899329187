import getConfig from 'next/config'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import slugify from 'slugify'
import { UnitApi } from 'src/common/types'
import { getListings, getNewVerifiedListings } from 'src/data/newListings'
import { segment } from 'src/lib/Segments'

import Chatlio from '@components/Chatlio'
import InfoToolTip from '@components/generic/InfoToolTip'
import { ActionButton } from '@components/index'
import Layout from '@components/layouts'
import DownloadApp from '@components/newLanding/downloadApp'
import LandingFaqs from '@components/newLanding/faqs'
import LandingFilters from '@components/newLanding/landingFilters'
import MarketPlace from '@components/newLanding/marketPlace'
// import SocialLinks from '@components/newLanding/socialLinks'
import ReadReview from '@components/newLayout/readReview'
import RentApartment from '@components/newLayout/rentApartment'
import ListingUnitCards from '@components/newUnit/listingUnitCards'
// import { Resource } from '@openigloo/common'
import { fetchFeaturedResources, setCurrentCity, setIsLoggedIn } from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { wrapper } from '@redux/store'

type Props = {
  title?: string
  url?: string | null
  initialList: UnitApi[]
}

const {
  publicRuntimeConfig: { STATIC_ROOT },
} = getConfig()

const openiglooLogo = `${STATIC_ROOT}/logo.png`

const verifiedInfo =
  'Verified openigloo listings are apartments we have confirmed are available. You can apply directly to verified listings on openigloo.'

const NewLandingPage = ({
  title = 'Read Landlord Reviews & Find Highly-Rated Apartments',
  url = 'https://www.openigloo.com/',
  initialList,
}: Props) => {
  const { isLoggedIn, token } = useAppSelector((state) => state.currentUser)
  const { cities } = useAppSelector((state) => state.misc)
  const dispatch = useThunkDispatch()
  const router = useRouter()
  const cover = '/landing-cover.png'

  const [selectedTab, setSelectedTab] = useState('reviews')
  const [showMore, setShowMore] = useState(false)

  const [listingsDataCount, setListingsDataCount] = useState(null)
  const [listingsLoad, setListingsLoad] = useState(true)
  const { filters, searchFilter, profileFilter } = useAppSelector((state) => state.newSearch)

  useEffect(() => {
    if (router.isReady && cities?.length > 0) {
      let city = cities.find(
        (x) => slugify(x.name.toLowerCase()) === (router?.query?.city ?? 'new-york-city')
      )
      if (city) {
        dispatch(setCurrentCity(city))
        localStorage.setItem('city', JSON.stringify(city))
      } else if (router?.query?.city === 'anywhere') {
        city = {
          id: 'other',
          name: 'Anywhere',
          center: {
            latitude: 40.7128,
            longitude: -74.006,
          },
        }
        dispatch(setCurrentCity(city))
        localStorage.setItem('city', JSON.stringify(city))
      }
    }
  }, [router, cities])

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setIsLoggedIn(false))
    }
  }, [])

  async function fetchListings(pageNo: number) {
    setListingsLoad(true)
    try {
      const data = {
        filters: { ...filters, neighborhoodIds: searchFilter.neighborhoodIds, ...profileFilter },
        buildingSlugs: searchFilter.buildingId ? [searchFilter.buildingId] : [],
      }

      const response = await getListings(token ?? null, data, pageNo ?? pageNo, 1)
      setListingsDataCount(response.count)
    } catch (error) {
      console.error('Failed to fetch listings:', error)
    } finally {
      setListingsLoad(false)
    }
  }

  useEffect(() => {
    if (filters && searchFilter && profileFilter) {
      fetchListings(1)
    }
  }, [filters, searchFilter, profileFilter])

  return (
    <Layout title={title} url={url} cover={cover} withBrand={false} canonicalURL={url}>
      <div>
        <div className="bg-light-10">
          <div className="mx-auto max-w-screen-lg py-4 px-4 pt-6 sm:py-8 lg:px-0">
            <div className="mx-auto flex w-fit items-center space-x-6 sm:block sm:space-x-0 md:relative md:z-[52] md:-mt-20">
              <div className="block sm:hidden">
                <Link href="/" passHref onClick={() => segment?.header_logo()}>
                  <a
                    onClick={segment.header_logo}
                    className="relative mb-6 flex h-14 w-24 items-center justify-center self-center hover:cursor-pointer"
                  >
                    <Image
                      src={openiglooLogo}
                      alt="Home"
                      layout="fill"
                      objectFit="contain"
                      unoptimized
                    />
                  </a>
                </Link>
              </div>
              <h1 className="mb-6 text-left text-xl font-semibold text-dark-900 sm:text-center md:!-ml-28">
                Find, Rent &{' '}
                <a
                  className="text-bluegray-300 underline"
                  href="/review"
                  onClick={() => segment?.header_review_title()}
                >
                  Review
                </a>{' '}
                Buildings and Landlords
              </h1>
            </div>
            <div className={`homepage-css rounded-2xl p-4 shadow sm:p-6 sm:shadow-none md:mt-8`}>
              <div className="mx-auto flex w-full max-w-sm  flex-row items-center justify-center rounded-3xl bg-light-20">
                <div
                  onClick={() => {
                    setSelectedTab('reviews')
                    segment?.header_read_review()
                  }}
                  className={`cursor-pointer text-center text-sm text-mid-300 ${
                    selectedTab == 'reviews' && ' !bg-primary font-semibold !text-light-0'
                  } w-1/2 rounded-3xl py-3`}
                >
                  Read Reviews
                </div>
                <div
                  onClick={() => {
                    setSelectedTab('apartments')
                    segment?.header_explore_listings()
                  }}
                  className={`cursor-pointer text-center text-sm text-mid-300 ${
                    selectedTab == 'apartments' && ' !bg-primary font-semibold !text-light-0'
                  } w-1/2 rounded-3xl py-3`}
                >
                  Rent Apartments
                </div>
              </div>
              {selectedTab == 'reviews' && <ReadReview hideTitle={true} />}
              {selectedTab == 'apartments' && (
                <RentApartment
                  hideTitle={true}
                  listingsLoad={listingsLoad}
                  listingsDataCount={listingsDataCount}
                />
              )}
            </div>
          </div>
        </div>
        <div className="bg-light-10">
          <div className="mx-auto max-w-screen-lg py-4 px-4 sm:py-8 lg:px-0">
            <MarketPlace />
            {/* {selectedTab == 'apartments' && <OpeniglooWork />} */}
            {/* <div className="relative mt-6">
              <div className="flex items-center justify-between pb-4">
                <h2 className="text-2xl font-semibold text-dark-900">Renter Resources</h2>
                <Link passHref href={'/blogs'}>
                  <a
                    rel="noreferrer"
                    className="text-sm text-primary underline"
                    onClick={() => {
                      segment.home_more_resources()
                    }}
                  >
                    More Resources
                  </a>
                </Link>
              </div>
              <div className="sm:-mx-2">
                {featuredResources && featuredResources.length > 0 ? (
                  <HorizontalScrollSection
                    customResponsive={{
                      small: {
                        breakpoint: { min: 0, max: 639 },
                        items: 1,
                        slidesToSlide: 1,
                        paritialVisibilityGutter: false,
                      },
                      large: {
                        breakpoint: { min: 640, max: 4000 },
                        items: 2,
                        slidesToSlide: 1,
                        paritialVisibilityGutter: false,
                      },
                    }}
                    customLeftArrowStyle={'bg-light-20 text-blue-200 sm:ml-2'}
                    customRightArrowStyle={'bg-light-20 text-blue-200 sm:mr-2'}
                    customContainerStyle="min-h-full"
                    itemClass="sm:px-2"
                    showOutlineArrow={true}
                  >
                    {filteredResources.map((fr) => (
                      <div key={fr.id} className="w-full">
                        <BlogCard blog={fr} key={fr.id} segmentBase="home" />
                      </div>
                    ))}
                  </HorizontalScrollSection>
                ) : null}
              </div>
              <div className="mt-6">
                <NycCalculatorCard segmentBase="home" />
              </div>
            </div>
            <div className="mt-6 rounded-2xl bg-white">
              <h2 className="pt-6 text-center text-xl font-semibold text-dark-900">
                NYC's First Rent Stabilization Map
              </h2>
              <div className="pt-4 text-center text-dark-700">
                There are nearly 1 million rent-stabilized apartments in NYC. Let's find them
                together!
              </div>
              <div className="relative mx-auto flex justify-center !py-6">
                <Image
                  src={blurmap}
                  alt="blurmap"
                  layout="fixed"
                  objectFit="cover"
                  className="rounded-xl blur-[2px]"
                  unoptimized
                />
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
                  <Link href={'/nyc/rent-stabilized-apartments'} passHref>
                    <a
                      className="rounded-full bg-bluegray-300 px-8 py-2 text-sm text-white"
                      onClick={() => segment?.home_explore_rentstabilizedmap()}
                    >
                      Explore map
                    </a>
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="mt-6">
              <div className="flex items-center space-x-4 pb-4">
                <div className="text-xl font-semibold text-dark-900">
                  Verified Apartment Listings
                </div>
                <InfoToolTip
                  tip={verifiedInfo}
                  id="verified-info"
                  direction={isMobile ? 'bottom' : 'right'}
                  mobileResponsive="sm:!ml-4 sm:!w-96 !-ml-4"
                />
              </div>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
                {initialList.slice(0, 3).map((listing, index) => (
                  <div key={`listing-${listing.id}-${index}`} className="bg-white">
                    <ListingUnitCards
                      unit={listing}
                      segmentBase="home"
                      showSnapshotButton={true}
                      disableFullScreenImage={true}
                    />
                  </div>
                ))}
                {showMore &&
                  initialList.slice(3, 6).map((listing, index) => (
                    <div key={`listing-${listing.id}-${index}`} className="bg-white">
                      <ListingUnitCards
                        unit={listing}
                        segmentBase="home"
                        showSnapshotButton={true}
                        disableFullScreenImage={true}
                      />
                    </div>
                  ))}
              </div>
              <div className="mt-4 flex justify-center">
                <ActionButton
                  label={
                    <div className="flex items-center space-x-2">
                      <div>{showMore ? 'Show less' : 'Show more'}</div>
                      {showMore ? (
                        <AiOutlineMinus color="#061938" size={16} />
                      ) : (
                        <AiOutlinePlus color="#061938" size={16} />
                      )}
                    </div>
                  }
                  customStyle="text-sm rounded-full bg-white border border-light-40 !text-dark-900"
                  style="none"
                  onClick={() => {
                    setShowMore((prev) => !prev)
                    showMore
                      ? segment?.home_verified_listings_collapse()
                      : segment?.home_verified_listings_expand()
                  }}
                />
              </div>
              <div className="mt-4 flex justify-center">
                <ActionButton
                  label={'Browse verified listings'}
                  onClick={() => {
                    segment?.home_browse_verified_listings()
                    router.push('/nyc/apartments-for-rent-nyc')
                  }}
                  customStyle="!py-4 rounded-full text-sm"
                />
              </div>
            </div>
            <LandingFilters />
            <LandingFaqs />
            <DownloadApp />
            {/* <SocialLinks /> */}
          </div>
        </div>
        <Chatlio />
      </div>
    </Layout>
  )
}

export const getServerSideProps = wrapper.getServerSideProps((store) => async () => {
  await store.dispatch(fetchFeaturedResources())
  const list = await getNewVerifiedListings(1, null, { isHomepage: 'yes' })
  return { props: { initialList: list.results } }
})

export default NewLandingPage
